<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
        class="log-form"
      >
        <el-row>
          <el-col>
            <el-form-item prop="accountName">
              <el-input
                v-model.trim="formData.accountName"
                clearable
                placeholder="账号名"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item prop="userIdStr">
              <el-input
                v-model.trim="formData.userIdStr"
                clearable
                placeholder="用户ID"
                @keyup.enter.native="handleSearch"
              />
            </el-form-item>
            <el-form-item prop="status">
              <el-select
                v-model="formData.status"
                placeholder="选择状态"
                clearable
              >
                <el-option
                  v-for="(item, index) in accountStateList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="groupId">
              <el-select
                v-model="formData.groupId"
                placeholder="选择账号组"
                clearable
              >
                <el-option
                  v-for="(item, index) in accountGroupAsOptionsInfo.options"
                  :key="index"
                  :label="item.groupName"
                  :value="item.groupId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.appId"
                clearable
                placeholder="选择应用"
              >
                <el-option
                  v-for="(item, index) in appIdList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              prop="useStart"
              label="使用时长"
            >
              <el-row>
                <el-col>
                  <el-input
                    v-model.number="formData.useStart"
                    clearable
                    placeholder="小时"
                  />
                  <span style="margin: 0 10px;">至</span>
                  <el-input
                    v-model.number="formData.useEnd"
                    clearable
                    placeholder="小时"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item
              prop="daterange"
              label="租借时间"
            >
              <el-date-picker
                v-model="formData.daterange"
                type="datetimerange"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:right>
        <div style="width: 160px;">
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
          <el-button
            type="primary"
            @click="handleExport"
          >
            导出
          </el-button>
        </div>
      </template>
    </BHeader>
    <el-table
      v-loading="logListInfo.loading"
      :data="logListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="leaseTime"
        label="开始租借时间"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ new Date(row.leaseTime * 1000).toLocaleString('chinese', { hour12: false }) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="accountName"
        label="账号名"
        min-width="100"
      />
      <el-table-column
        prop="groupName"
        label="所属组"
        min-width="100"
      />
      <el-table-column
        prop="appId"
        label="关联应用"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ (appIdMap[row.appId] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ accountStateMap[row.status].name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userIdStr"
        label="用户ID"
        width="180"
      />
      <el-table-column
        prop="useTime"
        label="使用时长"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ computedTime(row.useTime) }}
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="logListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.limit"
      @pagination="queryLogList(formData)"
    />
  </Layout>
</template>

<script>
import { accountStateList, accountStateMap, appIdMap, appIdList } from '@/utils/selectOptions.js'
import { mapActions, mapState } from 'vuex'

import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import { cloneDeep } from 'lodash'

import { getYesterdaySection } from '@/utils'

export default {
  name: 'RentAccountLog',
  data () {
    const validateTime = (rule, value, callback) => {
      if (this.formData.useStart === undefined && this.formData.useEnd === undefined) {
        return callback()
      } else if (this.formData.useStart === undefined && this.formData.useEnd !== undefined) {
        callback(new Error('请填写开始时间'))
      } else if (this.formData.useStart !== undefined && this.formData.useEnd === undefined) {
        callback(new Error('请填写结束时间'))
      } else if (isNaN(this.formData.useStart) || isNaN(this.formData.useEnd)) {
        callback(new Error('必须是数字'))
      } else if (this.formData.useStart < 0) {
        callback(new Error('开始时间必须大于0'))
      } else if (this.formData.useStart > this.formData.useEnd) {
        callback(new Error('结束时间必须大于开始时间'))
      } else {
        callback()
      }
    }
    return {
      accountStateList,
      accountStateMap,
      appIdMap,
      appIdList,
      rules: {
        useStart: [
          { validator: validateTime }
        ]
      },
      formData: {
        useStart: undefined,
        useEnd: undefined,
        accountName: '',
        accountId: '',
        groupId: '',
        status: '',
        daterange: getYesterdaySection('timestamp'),
        page: 1,
        size: 20
      },
      batchUploadTemplate: {
        list: [],
        header: ['开始租借时间', '账号名', '所属组', '关联应用', '状态', '用户ID', '使用时长'],
        fields: ['leaseTime', 'accountName', 'groupName', 'appId', 'status', 'userIdStr', 'useTime']
      }
    }
  },
  computed: {
    ...mapState('rentAccount', ['logListInfo', 'accountGroupAsOptionsInfo'])
  },
  created () {
    this.queryAccountGroupListAsOptions()
    this.queryLogList(this.formData)
  },
  methods: {
    ...mapActions('rentAccount', ['queryLogList', 'queryLogListForExport', 'queryAccountGroupListAsOptions']),
    handleSearch () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formData.page = 1
          this.queryLogList(this.formData)
        }
      })
    },
    computedTime (second) {
      const min = Math.floor(second % (60 * 60))
      const s = ('0' + second % 60).slice(-2)
      const m = ('0' + Math.floor(min / 60)).slice(-2)
      const H = Math.floor(second / (60 * 60))
      const h = H > 9 ? H : '0' + H
      return `${h}:${m}:${s}`
    },
    handleExport () {
      const formData = cloneDeep(this.formData)

      this.queryLogListForExport(formData)
        .then(data => {
          this.formatToDwonload(data)
          this.downloadExcel()
        })
    },
    formatToDwonload (data) {
      const temp = cloneDeep(data)
      temp.forEach(row => {
        row.leaseTime = new Date(row.leaseTime * 1000).toLocaleString('chinese', { hour12: false })
        row.appId = (this.appIdMap[row.appId] || {}).name || '未知'
        row.status = accountStateMap[row.status].name
        row.useTime = this.computedTime(row.useTime)
      })
      this.batchUploadTemplate.list = temp
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, '账号使用日志' + '_' + new Date().toLocaleDateString())
    }
  }
}
</script>

<style scoped lang="less">
  .log-form{
    .el-input,
    .el-select{
      width: 150px;
    }
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
      width: 270px;
    }
  }
</style>
